@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a:hover {
    @apply text-text-link;
  }

  @font-face {
    font-family: 'Almarena';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/almarena_regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Almarena';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/almarena_bold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Almarena Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/almarena_mono_light.woff2) format('woff2');
  }
}
